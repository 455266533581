import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./_redux/store";
import "./index.scss";

ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>

    {
      process.env.REACT_APP_SHOW_FB_CHAT &&
      <>
        <div id="fb-root"></div>

        <div id="fb-customer-chat" className="fb-customerchat"></div>
      </>
    }
  </>,
  document.getElementById("root")
);
