import { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

//
import { AuthRoute, PrivateRoute } from './auth-route';

//
import Layout from './layout/Layout';
import Loader from './_elements/Loader';

//
import { clearUiMessage } from './_redux/slices/ui';
import { getUserData } from './_redux/slices/users';
import { fetchNagarpalika } from './_redux/slices/nagarpalika';
import { fetchActiveFiscalYear } from './_redux/slices/fiscalyear';

//
import './sass/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import './components/Form/index.css';
import Application from './pages/Settings/Application';
import Consent from './pages/Consent';
import LoginWithConsent from './pages/LoginWithConsent';
import { Helmet } from 'react-helmet';
import { fetchAppData } from './_redux/slices/appData';
import Signout from './pages/signout/Signout';

// auth
const Login = lazy(() => import('./pages/auth/Login'));
const SignUp = lazy(() => import('./pages/auth/SignUp'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));

const Faq = lazy(() => import('./pages/Faq'));
const Support = lazy(() => import('./pages/Support'));

const Settings = lazy(() => import('./pages/Settings/Settings'));
const AddWard = lazy(() => import('./pages/Settings/Users/AddWard'));
const AddShakha = lazy(() => import('./pages/Settings/Users/AddShakha'));
const FiscalYear = lazy(() => import('./pages/Settings/FiscalYear'));
const Profile = lazy(() => import('./pages/Settings/Profile/Profile'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const Wards = lazy(() => import('./pages/Settings/Users/Wards'));
const Sections = lazy(() => import('./pages/Settings/Users/Sections'));
const WardUser = lazy(() => import('./pages/Settings/Users/WardUser'));
const ShakhaUser = lazy(() => import('./pages/Settings/Users/ShakhaUser'));
const NagarpalikaDetails = lazy(() => import('./pages/NagarpalikaDetails/NagarpalikaDetails'));
const Users = lazy(() => import('./pages/Users/Users'));
const Landing = lazy(() => import('./pages/Landing/Landing'));

/**
 *
 */
function App({ clearUiMessage, fetchNagarpalika, getUserData, fetchActiveFiscalYear }) {
	const { message, errors } = useSelector((state) => state.ui);
	const isLoggedIn = useSelector((state) => state.users.isLoggedIn);

	const { landingData } = useSelector((state) => state.appData);
	const nagarpalika = useSelector((state) => state.nagarplaika?.nagarpalika);

	const dispatch = useDispatch();

	useEffect(() => {
		getUserData();
		fetchNagarpalika();
		dispatch(fetchAppData());
	}, [fetchNagarpalika, dispatch, getUserData, isLoggedIn]);

	useEffect(() => {
		if (isLoggedIn) {
			fetchActiveFiscalYear();
		}
	}, [getUserData, fetchActiveFiscalYear, isLoggedIn]);

	useEffect(() => {
		if (message) {
			if (message.error === false) {
				toast.success(message.message);
				clearUiMessage();
			} else if (message) {
				toast.error(message.message);
				clearUiMessage();
			} else {
				toast.success(message);
			}
		}
		if (errors.length > 0) {
			errors.forEach((message) => {
				toast.error(message);
				clearUiMessage();
			});
		}
	}, [message, clearUiMessage, errors]);

	return (
		<Suspense fallback={<Loader />}>
			<Helmet>
				<link rel="icon" href={nagarpalika?.campaign_logo || nagarpalika?.main_logo} />
				<meta property="og:image" content={nagarpalika?.campaign_logo || nagarpalika?.main_logo} />
				<meta name="description" content={landingData?.system_desc} />
				<meta property="og:description" content={landingData?.system_desc} />
				<meta property="og:site_name" content={nagarpalika?.name_np} />
				<title>{`डिजिटल पालिका`}</title>
			</Helmet>
			{/* {process.env.REACT_APP_SHOW_FB_CHAT && (
				<Helmet>
					<script>
						{`
							var chatbox = document.getElementById('fb-customer-chat');
							chatbox.setAttribute('page_id', ${process.env.REACT_APP_FACEBOOK_PAGE_ID});
							chatbox.setAttribute('attribution', 'biz_inbox');
						`}
					</script>
					<script>
						{`
							window.fbAsyncInit = function () {
								FB.init({
									xfbml: true,
									version: 'v15.0',
								});
							};

							(function (d, s, id) {
								var js, fjs = d.getElementsByTagName(s)[0];
								if (d.getElementById(id)) return;
								js = d.createElement(s);
								js.id = id;
								js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
								fjs.parentNode.insertBefore(js, fjs);
							})(document, 'script', 'facebook-jssdk');
						`}
					</script>
				</Helmet>
			)} */}

			<Router>
				<Switch>
					<Route exact path="/" component={Landing} />
					<AuthRoute exact path="/login" component={Login} />

					<Route exact path="/signup" component={SignUp} />

					<Route exact path="/forgot-password" component={ForgotPassword} />
					<Route exact path="/password/reset" component={ResetPassword} />
					<Route exact path="/o/consent/:appId" component={LoginWithConsent} />
					<Route exact path="/signout" component={Signout} />
					<Route path="/" render={({ match }) => <RootRoutes basePath={match.path} />} />
				</Switch>

				<ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Router>
		</Suspense>
	);
}

export default connect(null, {
	clearUiMessage,
	getUserData,
	fetchNagarpalika,
	fetchActiveFiscalYear,
})(App);

const RootRoutes = () => (
	<Layout>
		<PrivateRoute exact path="/settings" component={Settings} />
		<PrivateRoute exact path="/fiscal-year/:pageNum" component={FiscalYear} />
		<PrivateRoute exact path="/application/:pageNum" component={Application} />
		<PrivateRoute exact path="/consent" component={Consent} />

		<PrivateRoute exact path="/wards/:pageNum" component={Wards} />
		<PrivateRoute exact path="/sections/:pageNum" component={Sections} />

		<PrivateRoute exact path="/ward/:id" component={WardUser} />
		<PrivateRoute exact path="/section/:id" component={ShakhaUser} />
		<PrivateRoute exact path="/settings/profile" component={Profile} />
		<PrivateRoute exact path="/change-password" component={ChangePassword} />
		{/* <PrivateRoute exact path="/settings/mayor" component={MayorTab} /> */}

		<PrivateRoute exact path="/faq" component={Faq} />
		<PrivateRoute exact path="/support" component={Support} />
		<PrivateRoute exact path="/create/ward/" component={AddWard} />
		<PrivateRoute exact path="/create/section/" component={AddShakha} />
		<PrivateRoute exact path="/ward/update/:id" component={AddWard} />
		<PrivateRoute exact path="/section/update/:id" component={AddShakha} />

		<PrivateRoute exact path="/nagarpalika/:pageNum" component={NagarpalikaDetails} />
		<PrivateRoute exact path="/users" component={Users} />
	</Layout>
);
