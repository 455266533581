import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage, setUiError } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	shakhas: [],
	count: 0,
	shakhaUsers: [],
};

// A slice
const shakhaSlice = createSlice({
	name: 'shakhas',
	initialState,
	reducers: {
		getshakhas: (state, action) => {
			state.shakhas = action.payload.data.results;
			state.count = action.payload.data.count;
		},
		getshakhaUsers: (state, action) => {
			state.shakhaUsers = action.payload.data;
		},
		createshakhaUser: (state, action) => {
			state.shakhaUsers.users.unshift(action.payload);
		},
		editshakhaUser: (state, action) => {
			const editedUser = { ...state.shakhaUsers };

			const editedIndex = editedUser.users.findIndex((user) => user.id === action.payload.id);
			if (editedIndex !== -1) {
				editedUser.users[editedIndex] = action.payload;
			}
			state.shakhaUsers = editedUser;
		},
		activeshakhaUser: (state, action) => {
			const tempUser = { ...state.shakhaUsers };
			const editedIndex = tempUser.users.findIndex((user) => user.id === action.payload.profile_id);
			if (editedIndex !== -1) {
				if (action.payload.status === 'active') {
					tempUser.users[editedIndex].is_active = true;
				} else {
					tempUser.users[editedIndex].is_active = false;
				}
			}
			state.shakhaUsers = tempUser;
		},
		deleteshakhaUser: (state, action) => {
			const tempUsers = { ...state.shakhaUsers };
			const index = tempUsers.users.findIndex((user) => user.id === action.payload);
			tempUsers.users.splice(index, 1);
			state.shakhaUsers = tempUsers;
		},
	},
});

// Actions generated from the slice
const { getshakhas, getshakhaUsers, createshakhaUser, activeshakhaUser, editshakhaUser, deleteshakhaUser } =
	shakhaSlice.actions;

// export user selector to get the slice in any component
export const shakhaSelector = (state) => state.shakhas;

// export The reducer
const shakhaReducer = shakhaSlice.reducer;

export default shakhaReducer;

// Actions
export const fetchshakhas =
	(page = 1) =>
	async (dispatch) => {
		try {
			const shakhas = await fetchWrapper.get(`/sakha/?limit=10&offset=${(page - 1) * 10}`);
			dispatch(getshakhas(shakhas));
		} catch (error) {}
	};
export const fetchshakhaUsers = (id) => async (dispatch) => {
	try {
		const shakhaUsers = await fetchWrapper.get(`/sakha/${id}/`);
		dispatch(getshakhaUsers(shakhaUsers));
	} catch (error) {}
};

export const postshakhaUser = (data, hideUserForm, id) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`/profile/ward-user/`, data, true);
		dispatch(createshakhaUser(response.data));
		dispatch(setUiMessage(response.message));
		hideUserForm();
	} catch (error) {
		if (error.error?.non_field_error) {
			dispatch(setUiError(error.error.non_field_error));
		} else {
			if (error?.error && Object.keys(error.error).length) {
				Object.keys(error.error).map((errorKey) => {
					console.log(error.error[errorKey][0], 'error.error[errorKey][0]');
					return toast.error(error.error[errorKey][0]);
				});
			} else {
				for (let err in error?.errors) {
					dispatch(setUiError(error?.errors[err]));
				}
			}
		}
	}
};

export const patchshakhaUser = (data, id, setShowAdd, showAdd) => async (dispatch) => {
	try {
		const response = await fetchWrapper.patch(`/profile/ward-user/${id}/`, data, true);
		// console.log(response, data);

		dispatch(editshakhaUser(response?.data));
		setShowAdd(false);
		dispatch(setUiMessage(response.message));
	} catch (error) {
		console.log(error, 'error');
		dispatch(setUiError(error.message));
	}
};

export const activateKawashakhaUser = (body) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`/update-kawa-status/`, body);
		dispatch(activeshakhaUser(body));
		dispatch(setUiMessage(response.message));
	} catch (error) {}
};

export const removeshakhaUser = (id) => async (dispatch) => {
	try {
		const response = await fetchWrapper.delete(`/profile/ward-user/${id}/`);

		dispatch(deleteshakhaUser(id));
		if (response?.error?.message) {
			dispatch(setUiError(response.error.message));
		} else {
			dispatch(setUiMessage('User deleted successfully'));
		}
	} catch (error) {}
};
