import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { jwtCheck, setToken } from '../../_helpers/jwt-check';
import { setUiError, setUiMessage } from './ui';
import { toast } from 'react-toastify';

export const initialState = {
	loading: false,
	hasErrors: false,
	users: [],
	user: null,
	profile: null,
	isLoggedIn: jwtCheck() ? true : false,
};

// A slice
const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setUserProfile: (state, action) => {
			state.profile = action.payload;
		},
		updateUser: (state, action) => {
			// let tempuser = {
			// 	...state.profile,
			// 	email: action.payload.email,
			// 	username: action.payload.email,
			// 	phone: action.payload.phone_np,
			// 	user: {
			// 		...state.profile.user,
			// 		fullname_np: action.payload.fullname_np,
			// 		fullname_en: action.payload.fullname_en,
			// 		fullname: action?.payload?.fullname || action.payload.fullname_np,
			// 		email: action.payload.email,
			// 		phone_en: action.payload.phone_en,
			// 		phone_np: action.payload.phone_np,
			// 	},
			// };
			state.profile = { ...state.profile, ...action.payload };
		},
		setUserLogin: (state, action) => {
			state.isLoggedIn = true;
		},
		// setUserLogout: (state, action) => initialState,
		setUserLogout: (state, action) => {
			state.user = null;
			state.profile = null;
			state.isLoggedIn = false;
		},
		addUser: (state, action) => {
			state.users.unshift(action.payload);
		},
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getUsersSuccess: (state, action) => {
			state.users = action.payload;
			state.loading = false;
			state.hasErrors = false;
		},
		getUsersFailure: (state) => {
			state.loading = false;
			//handling Errors
			state.hasErrors = true;
		},
	},
});

// Actions generated from the slice
const {
	setUser,
	setUserLogin,
	setUserLogout,
	addUser,
	startLoading,
	stopLoading,
	getUsersFailure,
	updateUser,
	setUserProfile,
} = usersSlice.actions;

// export user selector to get the slice in any component
export const usersSelector = (state) => state.users;
export const userSelector = (state) => state.users.user;

// export The reducer
const userReducer = usersSlice.reducer;

export default userReducer;

export const createUser = (User) => async (dispatch) => {
	try {
		dispatch(addUser(User));
	} catch (error) {
		dispatch(getUsersFailure());
	}
};

export const patchUser = (data, id) => async (dispatch) => {
	try {
		dispatch(startLoading());
		const response = await fetchWrapper.post(`/profile/info/`, data);
		dispatch(updateUser(response.data));
		dispatch(setUiMessage("सफलतापूर्वक सम्पादन गरियो।"));
		dispatch(stopLoading());
	} catch (error) {
		let errorMessage = error.error?.non_field_error || error.message;
		dispatch(setUiError(errorMessage));
		dispatch(stopLoading());
	}
};

export const loginUser = (credentials, history) => async (dispatch) => {
	try {
		dispatch(startLoading());
		let response = await fetchWrapper.post('/token/', credentials);
		let accessToken = {
			token: response.data.access,
		};
		let refreshToken = {
			token: response.data.refresh,
		};
		let role = response.data.role;
		// let
		setToken(accessToken, refreshToken, role);
		dispatch(stopLoading());
		dispatch(setUserLogin(response.data));
		role === 'ito_admin' ? history.push('/settings/profile') : history.push('/application/user');
	} catch (err) {
		dispatch(stopLoading());
		console.log(err, 'err')
		if (err.status === 401) {
			toast.error('माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन।');
			return Promise.reject(err);
		}
		else if (err.status === 500) {
			toast.error('यो कार्य हाल सम्भव छैन। केहि समय पछि पुन: प्रयास गर्नुहोस्।');
			return Promise.reject(err);
		}
	}
};

export const getUserData = () => async (dispatch) => {
	try {
		const user = await fetchWrapper.get(`/user/info/`);
		dispatch(setUser(user.data));
	} catch (err) {
		dispatch(setUserLogout());
	}
	const profile = await fetchWrapper.get(`/profile/info/`);
	dispatch(setUserProfile(profile.data));
};

export const logoutUser = (history) => async (dispatch) => {
	localStorage.removeItem('user');
	localStorage.removeItem('userRefresh');
	localStorage.removeItem('userId');
	localStorage.removeItem('messagePermission');
	dispatch(setUserLogout());
	history?.push('/');
};
