/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
// import { Dropdown } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { logoutUser } from '../_redux/slices/users';
// import { useDispatch } from 'react-redux';
import {
	// handleChangeLanguage,
	languageSelector,
} from '../_redux/slices/languages';
import { nepaliNumberConverter } from '../_helpers/methods';
import { Fragment } from 'react';
// import ChangePasswordModal from './ChangePasswordModal';
// import { Link } from 'react-router-dom';

// import { BiUser } from 'react-icons/bi';
// import { CgPassword } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import BackButton from '../_elements/BackButton';

const Header = ({ logoutUser }) => {
	const { user, profile } = useSelector((state) => state.users);
	// console.log(user, "user");

	// const dispatch = useDispatch();
	const {
		language,
		//  fontType
	} = useSelector(languageSelector);
	const activeFiscalYear = useSelector((state) => state.fiscal.activeFiscalYear);
	// const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	// const handleShowChangePasswordModal = () => {
	// 	setShowChangePasswordModal(true);
	// };
	// const handleCloseChangePasswordModal = () => {
	// 	setShowChangePasswordModal(false);
	// };

	return (
		<div className="header">
			<div className="header-inner">
				<div className="header-left d-flex align-items-center">
					{/* <FaBars size="18" color="#fff" className="mr-4" /> */}
					<BackButton />
					<h4 className="text-white ml-4">
						{activeFiscalYear && (
							<span>
								{language === 'nepali' ? (
									<Fragment>आ.व. : {nepaliNumberConverter(activeFiscalYear?.title)}</Fragment>
								) : (
									<Fragment>आ.व. : {activeFiscalYear?.title}</Fragment>
								)}
							</span>
						)}{' '}
					</h4>
				</div>
				<div className="header-right">
					<img src="/assets/images/avatar.png" className="header-avatar" alt="" />

					{user && (
						<div
							css={css`
								display: flex;
								flex-direction: column;
								.role {
									font-size: 12px;
								}
							`}
						>
							<p>
								{language === 'nepali'
									? profile?.fullname_np ||
									  profile?.fullname ||
									  (profile?.email?.split('@') && profile?.email?.split('@')[0])
									: profile?.fullname_en ||
									  profile?.fullname ||
									  (profile?.email?.split('@') && profile?.email?.split('@')[0])}
							</p>
							<p className="role">
								{language === 'nepali' ? profile?.post_np || profile?.role : profile?.post_en || profile?.role}
							</p>
						</div>
					)}
					<span
						role="button"
						title={language === 'nepali' ? 'लगआउट' : 'Logout'}
						className="bg-white text-primary py-1 px-2 ml-3 rounded-pill"
						onClick={() => logoutUser()}
					>
						<FiLogOut />
						{/* {language === 'nepali' ? 'लगआउट' : 'Logout'} */}
					</span>

					{/* <Dropdown>
						<Dropdown.Toggle
							variant="primary"
							id="dropdown-basic"
							css={css`
								&.dropdown-toggle {
									color: white !important;
								}
							`}
						></Dropdown.Toggle>

						<Dropdown.Menu
							css={css`
								transform: translate(-155px, 40px) !important;
							`}
						>
							<Link
								css={css`
									display: block;
									width: 100%;
									padding: 0.25rem 1.5rem;
									clear: both;
									font-weight: 400;
									color: #212529;
									text-align: inherit;
									white-space: nowrap;
									background-color: transparent;
									border: 0;
									:hover {
										color: #16181b;
										text-decoration: none;
										background-color: #f8f9fa;
									}
								`}
								to="/settings/profile"
							>
								<BiUser className="mr-2" />
								{language === 'nepali' ? 'मेरो प्रोफाइल' : 'My Profile'}
							</Link>

							<Dropdown.Item
								onClick={() => {
									handleShowChangePasswordModal();
								}}
							>
								<CgPassword className="mr-2" />
								{language === 'nepali' ? 'पासवर्ड परिवर्तन गर्नुहोस्' : 'Change password'}
							</Dropdown.Item>
							<Dropdown.Item onClick={() => logoutUser()}>
								<FiLogOut className="mr-2" />
								{language === 'nepali' ? 'लगआउट' : 'Logout'}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown> */}
					{/* <h5>{language === 'english' ? 'English' : 'नेपाली'}</h5> */}
					{/* <Dropdown>
						<Dropdown.Toggle
							variant="primary"
							id="dropdown-language"
							css={css`
								&.dropdown-toggle {
									color: white !important;
								}
							`}
						></Dropdown.Toggle>
						<Dropdown.Menu
							css={css`
								transform: translate(-150px, 40px) !important;
							`}
						>
							<Dropdown.Item onClick={() => dispatch(handleChangeLanguage({ language: 'english' }))}>
								English
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() =>
									dispatch(
										handleChangeLanguage({
											language: 'nepali',
											fontType: 'traditional',
										})
									)
								}
							>
								नेपाली
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown> */}
				</div>
				{/* {showChangePasswordModal && (
					<ChangePasswordModal
						show={showChangePasswordModal}
						handleCloseChangePasswordModal={handleCloseChangePasswordModal}
						showChangePasswordModal={showChangePasswordModal}
					/>
				)} */}
			</div>
		</div>
	);
};

export default connect(null, { logoutUser })(Header);
