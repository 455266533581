import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { nepaliNumbers } from '../../_datas';
import { StyledInput, StyledTextArea } from './style/StyledIndex';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import setUnicode from '../../_helpers/language/type';
import { languageSelector } from '../../_redux/slices/languages';
import { nepaliNumber } from '../../_helpers/methods';
import { handleConvertNepali } from '../../_helpers/language/fonts';

function InputField({
	type,
	language,
	getInputData,
	register,
	elementId,
	value,
	errors = {},
	setValue,
	setConvertedDates,
	properties = {},
	validation = {},
	autoComplete = 'on',
	placeHolder = '',
	disabled,
	handleKeyPress,
	...rest
}) {
	const [nepali, setNepali] = useState(value);
	const { fontType } = useSelector(languageSelector);
	function moveFocus(e) {
		e.target.parentElement.nextElementSibling?.querySelector('input').focus();
	}

	useEffect(() => {
		setNepali(value);
	}, [value]);

	function switchFocus(e) {
		let val = e.target.value;
		const [_, suff] = elementId.split('.');
		console.log(_, '_');
		switch (suff) {
			case 'year':
				if (val.length === 4) {
					moveFocus(e);
				}
				break;
			case 'month':
				val.length === 2 && moveFocus(e);
				break;
			case 'day':
				val.length === 2 && moveFocus(e);
				break;
			default:
				break;
		}
	}

	function fillDigit(e) {
		let val = e.target.value;
		if (val) {
			//eslint-disable-next-line
			const [pref, suff] = elementId.split('.');
			switch (suff) {
				case 'month':
					if (val.length >= 2) {
						return null;
					}
					if (language === 'english') {
						val = val.padStart(2, 0);
					} else {
						val = val.padStart(2, '०');
					}
					setValue(elementId, val);
					setNepali(val);
					break;
				case 'day':
					if (val.length >= 2) {
						return null;
					}
					if (language === 'english') {
						val = val.padStart(2, 0);
					} else {
						val = val.padStart(2, '०');
					}
					setValue(elementId, val);
					setNepali(val);
					break;
				default:
					break;
			}
		}
	}

	const englishNumber = (number) => {
		let strEngNum = '';
		for (let i = 0; i < number.length; i++) {
			const index = nepaliNumbers.indexOf(number[i]);
			strEngNum += index !== -1 ? index : number[i];
		}
		return String(strEngNum);
	};
	function setDate(e) {
		const [id_prefix, id_suffix] = elementId.split('.');
		let id = `${id_prefix}.${id_suffix}`;
		if (language === 'nepali' && elementId === `${id_prefix}.${id_suffix}`) {
			setConvertedDates({ id: id, value: e.target.value });
		} else if (language === 'english' && elementId === `${id_prefix}.${id_suffix}`) {
			setConvertedDates({
				id: `${id_prefix}.${id_suffix}`,
				value: e.target.value,
			});
		}
	}

	const isNumeric = (number) => {
		// eslint-disable-next-line no-self-compare
		if (+number === +number) {
			// if is a number
			return true;
		}
		return false;
	};

	function checkValue(str, max) {
		if (str.charAt(0) !== '0' || str === '00') {
			var num = parseInt(str);
			if (isNaN(num) || num <= 0 || num > max) num = 1;
			str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
		}
		return str;
	}

	const handleNepaliTypeing = (e) => {
		const text = e.target.value;
		let output = '';

		switch (type) {
			case 'text':
			case 'textarea':
				if (language === 'nepali') {
					if (fontType === 'preeti' || fontType === 'pcs_nepali' || fontType === 'kantipur') {
						output = handleConvertNepali(fontType, text);
						setNepali(output);
						return getInputData(output);
					}
					setNepali(text);
					return getInputData(text);
				} else if (language === 'english') {
					setNepali(text);
					return getInputData(text);
				}
				break;
			case 'number':
				if (language === 'nepali') {
					output = nepaliNumber(text);
					setNepali(output);
					e.target.value = output;
					return getInputData(output);
				} else if (language === 'english') {
					for (let i = 0; i < text.length; i++) {
						output += isNumeric(text[i]) ? text[i] : '';
					}
					setNepali(output);
					return getInputData(output);
				}
				break;

			case 'date':
				if (language === 'english') {
					if (text.length > 14) return null;
					if (/\D\/$/.test(text)) output = text.substr(0, text.length - 3);
					else output = text;
					let values = output.split('/').map(function (v) {
						return v.replace(/\D/g, '');
					});
					if (values[1]) values[1] = checkValue(values[1], 12);
					if (values[2]) values[2] = checkValue(values[2], 32);

					let dateOutput = values.map(function (v, i) {
						return v.length === 4 && i === 0 ? v + ' / ' : v.length === 2 && i > 0 && i <= 2 ? v + ' / ' : v;
					});
					const dateString = dateOutput.join('').substr(0, 14);
					setNepali(dateString);
					return getInputData(dateString);
				} else if (language === 'nepali') {
					if (text.length > 14) return null;
					const numberText = englishNumber(text);

					if (/\D\/$/.test(numberText)) output = numberText.substr(0, numberText.length - 3);
					else output = numberText;
					let values = output.split('/').map(function (v) {
						return v.replace(/\D/g, '');
					});

					if (values[1]) values[1] = checkValue(values[1], 12);

					if (values[2]) values[2] = checkValue(values[2], 32);

					let dateOutput = values.map(function (v, i) {
						return v.length === 4 && i === 0
							? nepaliNumber(v) + ' / '
							: v.length === 2 && i > 0 && i <= 2
								? nepaliNumber(v) + ' / '
								: nepaliNumber(v);
					});
					const dateString = dateOutput.join('').substr(0, 14);
					setNepali(dateString);
					return getInputData(dateString);
				}
				break;
			default:
				break;
		}
	};

	if (register) {
		if (type === 'textarea') {
			return (
				<Fragment>
					<StyledTextArea
						rows="5"
						id={elementId}
						value={nepali}
						autoComplete={autoComplete}
						{...rest}
						{...register(elementId.split("'").join('&&&&'), validation)}
						onChange={(e) => {
							if (properties.isBlock) {
								e.target.value = e.target.value.toUpperCase();
							}
							handleNepaliTypeing(e);
							if (elementId.startsWith('date_of_birth') || elementId.startsWith('date_application')) {
								setDate(e);
							}
							register(elementId).onChange(e);
						}}
						placeholder={placeHolder}
						onKeyPress={(e) => {
							if (language === 'nepali' && (type === 'text' || type === 'textarea')) {
								if (fontType === 'traditional' || fontType === 'romanized') {
									const { value, newEndPos } = setUnicode(e, fontType);
									setValue(elementId, value);
									setNepali(value);
									getInputData(value);
									e.target.setSelectionRange(newEndPos, newEndPos);
								}
							}
							if (e.key === 'Enter' && handleKeyPress) {
								handleKeyPress(e);
								if (setValue) {
									setValue(elementId, '');
								}
								setNepali('');
							}
						}}
						onKeyUp={(e) => {
							if (elementId.startsWith('date')) {
								switchFocus(e);
							}
						}}
						onBlur={(e) => {
							if (elementId.startsWith('date')) {
								fillDigit(e);
							}
						}}
						disabled={disabled}
					/>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<StyledInput
					id={elementId}
					value={nepali}
					autoComplete={autoComplete}
					{...rest}
					{...register(elementId.split("'").join('&&&&'), validation)}
					onChange={(e) => {
						if (properties.isBlock) {
							e.target.value = e.target.value.toUpperCase();
						}
						handleNepaliTypeing(e);
						if (elementId.startsWith('date_of_birth') || elementId.startsWith('date_application')) {
							setDate(e);
						}
						register(elementId).onChange(e);
					}}
					placeholder={placeHolder}
					onKeyPress={(e) => {
						if (language === 'nepali' && (type === 'text' || type === 'textarea')) {
							if (fontType === 'traditional' || fontType === 'romanized') {
								const { value, newEndPos } = setUnicode(e, fontType);
								setValue(elementId, value);
								setNepali(value);
								getInputData(value);
								e.target.setSelectionRange(newEndPos, newEndPos);
							}
						}
						if (e.key === 'Enter' && handleKeyPress) {
							handleKeyPress(e);
							if (setValue) {
								setValue(elementId, '');
							}
							setNepali('');
						}
					}}
					onKeyUp={(e) => {
						if (elementId.startsWith('date')) {
							switchFocus(e);
						}
					}}
					onBlur={(e) => {
						if (elementId.startsWith('date')) {
							fillDigit(e);
						}
					}}
					disabled={disabled}
				/>
			</Fragment>
		);
	} else {
		return type === 'textarea' ? (
			<StyledTextArea
				rows="5"
				id={elementId}
				onChange={handleNepaliTypeing}
				onKeyPress={(e) => {
					if (language === 'nepali') {
						if (fontType === 'traditional' || fontType === 'romanized') {
							const { value, newEndPos } = setUnicode(e, fontType);
							setNepali(value);
							getInputData(value);
							e.target.setSelectionRange(newEndPos, newEndPos);
						}
					}
					if (e.key === 'Enter' && handleKeyPress) {
						handleKeyPress(e);
						if (setValue) {
							setValue(elementId, '');
						}
						setNepali('');
					}
				}}
				placeholder={placeHolder}
				value={nepali}
				autoComplete={autoComplete}
				{...rest}
				disabled={disabled}
			/>
		) : (
			<StyledInput
				id={elementId}
				onChange={handleNepaliTypeing}
				onKeyPress={(e) => {
					if (language === 'nepali' && type === 'text') {
						if (fontType === 'traditional' || fontType === 'romanized') {
							const { value, newEndPos } = setUnicode(e, fontType);
							setNepali(value);
							getInputData(value);
							e.target.setSelectionRange(newEndPos, newEndPos);
						}
					}
					if (e.key === 'Enter' && handleKeyPress) {
						handleKeyPress(e);
						if (setValue) {
							setValue(elementId, '');
						}
						setNepali('');
					}
				}}
				placeholder={placeHolder}
				value={nepali}
				autoComplete={autoComplete}
				{...rest}
				disabled={disabled}
			/>
		);
	}
}

InputField.defaultProps = {
	getInputData: () => { },
	language: 'nepali',
	type: 'text',
	value: '',
};

InputField.propTypes = {
	type: PropTypes.string,
	language: PropTypes.string,
	style: PropTypes.any,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onkeydown: PropTypes.func,
	handleKeyPress: PropTypes.func,
};

export default connect(null, {})(InputField);
