import React from 'react';
import { useHistory } from 'react-router-dom';
// import { Button } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { languageSelector } from "../_redux/slices/languages";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function BackButton({ showBack = true, showFront = true }) {
	const history = useHistory();
	// const { language } = useSelector(languageSelector);
	return (
		<div className="">
			{showBack
				&&
				<span
					title="go back"
					role="button"
					className="bg-white py-1 px-2 rounded-lg mr-2"
					onClick={() => history.goBack()}
				>
					<FaChevronLeft />
				</span>
			}
			{showFront &&
				<span
					title="go forward"
					role="button"
					className="bg-white py-1 px-2 rounded-lg"
					onClick={() => history.goForward()}
				>
					<FaChevronRight />
				</span>
			}
		</div>
	);
}

export default BackButton;
