import React from 'react';

const Consent = () => {
	return (
		<div className="w-100 h-100 d-flex justify-content-center align-items-center">
			<a
				href="http://localhost:8848/o/consent/1234"
				target="_blank"
				rel="noreferrer"
				className="btn btn-light border border-2 rounded-pill my-4"
			>
				Sign In With Auth Server
			</a>
		</div>
	);
};

export default Consent;
