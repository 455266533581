import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	loading: false,
	message: null,
	messages: [],
	errors: [],
};

// A slice

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		loadingUi: (state, action) => {
			state.loading = true;
		},
		stopLoadingUi: (state, action) => {
			state.loading = false;
		},
		clearMessage: (state, action) => {
			state.message = null;
		},
		setMessage: (state, action) => {
			try {
				state.message = {
					error: false,
					message: action?.payload,
				};
			} catch (err) {
				state.message = {
					error: true,
				};
			}
		},
		setErrors: (state, action) => {
			try {
				state.errors = action.payload;
			} catch (error) {}
		},
		setError: (state, action) => {
			try {
				state.message = {
					error: true,
					message: typeof action?.payload === 'string' ? action?.payload : action?.payload[0],
				};
			} catch (err) {
				state.message = {
					error: true,
				};
			}
		},
	},
});

const { loadingUi, stopLoadingUi, clearMessage, setMessage, setError, setErrors } = uiSlice.actions;

export const uiSelector = (state) => state.ui;

const uiReducer = uiSlice.reducer;

export default uiReducer;

// Actions
export const startUiLoading = () => async (dispatch) => {
	dispatch(loadingUi());
};

export const stopUiLoading = () => async (dispatch) => {
	dispatch(stopLoadingUi());
};

export const clearUiMessage = () => async (dispatch) => {
	dispatch(clearMessage());
};

export const setUiError = (errorMessage) => async (dispatch) => {
	dispatch(setError(errorMessage));
};

export const setUiMessage = (message) => async (dispatch) => {
	dispatch(setMessage(message));
};

export const setFormError = (formError) => async (dispatch) => {
	let messages = [];
	for (let error in formError) {
		messages.push(formError[error].message);
	}
	dispatch(setErrors(messages));
};
