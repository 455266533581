import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage, setUiError } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	wards: [],
	count: 0,
	wardUsers: [],
	wardInfo: {},
};

// A slice
const wardSlice = createSlice({
	name: 'wards',
	initialState,
	reducers: {
		getWards: (state, action) => {
			state.wards = action.payload.data.results;
			state.count = action.payload.data.count;
		},
		getWardUsers: (state, action) => {
			state.wardUsers = action.payload.data.users;
			state.wardInfo = action.payload.data;
		},
		createWardUser: (state, action) => {
			state.wardUsers.unshift(action.payload);
		},
		editWardUser: (state, action) => {
			const editedUser = state.wardUsers.map((user) => {
				if (user.id === action.payload.id) {
					return action.payload;
				} else {
					return user;
				}
			});

			state.wardUsers = editedUser;
		},
		activeWardUser: (state, action) => {
			const tempUser = [...state.wardUsers];
			const editedIndex = tempUser.findIndex((user) => user.id === action.payload.profile_id);
			if (editedIndex !== -1) {
				if (action.payload.status === 'active') {
					tempUser[editedIndex].is_active = true;
				} else {
					tempUser[editedIndex].is_active = false;
				}
			}
			state.wardUsers = tempUser;
		},
		deleteWardUser: (state, action) => {
			const tempUsers = [...state.wardUsers];
			const index = tempUsers.findIndex((user) => user.id === action.payload);
			tempUsers.splice(index, 1);
			state.wardUsers = tempUsers;
		},
	},
});

// Actions generated from the slice
const { getWards, getWardUsers, createWardUser, activeWardUser, editWardUser, deleteWardUser } = wardSlice.actions;

// export user selector to get the slice in any component
export const wardSelector = (state) => state.wards;

// export The reducer
const wardReducer = wardSlice.reducer;

export default wardReducer;

// Actions
export const fetchWards =
	(page = 1) =>
	async (dispatch) => {
		try {
			const wards = await fetchWrapper.get(`/ward/?limit=10&offset=${(page - 1) * 10}`);
			dispatch(getWards(wards));
		} catch (error) {}
	};
export const fetchWardUsers = (id) => async (dispatch) => {
	try {
		const wardUsers = await fetchWrapper.get(`/ward/${id}/`);
		dispatch(getWardUsers(wardUsers));
	} catch (error) {}
};

export const postWardUser = (data, hideUserForm) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`/profile/ward-user/`, data, true);
		dispatch(createWardUser(response.data));
		dispatch(setUiMessage(response.message));
		hideUserForm();
	} catch (error) {
		if (error.error?.non_field_error) {
			dispatch(setUiError(error.error.non_field_error));
		} else {
			if (error?.error && Object.keys(error.error).length) {
				Object.keys(error.error).map((errorKey) => {
					console.log(error.error[errorKey][0], 'error.error[errorKey][0]');
					return toast.error(error.error[errorKey][0]);
				});
			} else {
				for (let err in error?.errors) {
					dispatch(setUiError(error?.errors[err]));
				}
			}
		}
	}
};

export const patchWardUser = (data, id, setShowAdd, showAdd) => async (dispatch) => {
	try {
		const response = await fetchWrapper.patch(`/profile/ward-user/${id}/`, data, true);
		// console.log(response, data);

		dispatch(editWardUser(response?.data));
		setShowAdd(false);
		dispatch(setUiMessage(response.message));
	} catch (error) {
		console.log(error, 'error');
		dispatch(setUiError(error.message));
	}
};

export const activateKawaWardUser = (body) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`/update-kawa-status/`, body);
		dispatch(activeWardUser(body));
		dispatch(setUiMessage(response.message));
	} catch (error) {}
};

export const removeWardUser = (id) => async (dispatch) => {
	try {
		const response = await fetchWrapper.delete(`/profile/ward-user/${id}/`);

		dispatch(deleteWardUser(id));
		if (response?.error?.message) {
			dispatch(setUiError(response.error.message));
		} else {
			dispatch(setUiMessage('User deleted successfully'));
		}
	} catch (error) {}
};
