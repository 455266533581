import {
    FaFileExcel,
    FaFileAlt,
    FaCertificate,
    FaMoneyBill,
    FaNewspaper,
    FaClipboardCheck,
    FaFile,
    FaAddressCard,
    FaHome,
    FaCity,
    FaTractor,
    FaBuilding,
    FaWarehouse,
    FaDesktop,
    FaMoneyCheck,
    FaFileInvoice,
} from 'react-icons/fa';

const ICONS = {
    FaFileExcel: <FaFileExcel />,
    FaFileAlt: <FaFileAlt />,
    FaCertificate: <FaCertificate />,
    FaMoneyBill: <FaMoneyBill />,
    FaNewspaper: <FaNewspaper />,
    FaClipboardCheck: <FaClipboardCheck />,
    FaFile: <FaFile />,
    FaAddressCard: <FaAddressCard />,
    FaHome: <FaHome />,
    FaCity: <FaCity />,
    FaTractor: <FaTractor />,
    FaBuilding: <FaBuilding />,
    FaWarehouse: <FaWarehouse />,
    FaDesktop: <FaDesktop />,
    FaMoneyCheck: <FaMoneyCheck />,
    FaFileInvoice: <FaFileInvoice />,
}


export const ICONS_ARRAY = ['FaFileExcel',
    'FaFileAlt',
    'FaCertificate',
    'FaMoneyBill',
    'FaNewspaper',
    'FaClipboardCheck',
    'FaFile',
    'FaAddressCard',
    'FaHome',
    'FaCity',
    'FaTractor',
    'FaBuilding',
    'FaWarehouse',
    'FaDesktop',
    'FaMoneyCheck',
    'FaFileInvoice'];
export default function DynamicIcons({ name }) {
    if (!name) {
        name = 'FaDesktop'
    }
    let icon = ICONS[name];
    return <>
        {icon}
    </>;
}