import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './users';
import languageReducer from './languages';
import uiReducer from './ui';
import fiscalReducer from './fiscalyear';
import wardReducer from './ward';
import shakhaReducer from './section';
import mayorsReducer from './mayors';
import nagarpalikaReducer from './nagarpalika';
import applicationReducer from './application';
import appReducer from './appData';

const rootReducer = combineReducers({
	users: userReducer,
	language: languageReducer,
	ui: uiReducer,
	fiscal: fiscalReducer,
	wards: wardReducer,
	shakhas: shakhaReducer,
	mayors: mayorsReducer,
	nagarpalika: nagarpalikaReducer,
	applications: applicationReducer,
	appData: appReducer,
});

export default rootReducer;
