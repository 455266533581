/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { data } from './SidebarData';
import SidebarButtonRenderer from './SidebarButtonRenderer';
import { languageSelector } from '../../_redux/slices/languages';

const Sidebar = () => {
	const { user, profile } = useSelector((state) => state.users);
	const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
	const nagarpalikaInfo = useSelector((state) => state.users?.user?.nagarpalika_info);

	const { language } = useSelector(languageSelector);
	return (
		<div className="sidebar-wrapper">
			<div className="sidebar-top">
				<Link to="/application/user">
					<img src={'/assets/images/nep_logo.png'} className="logo" alt="" />
				</Link>
				<div>
					<h5>
						{nagarpalikaInfo ? (
							<div>
								<h5>{language === 'english' ? nagarpalikaInfo.name_en : nagarpalikaInfo.name_np}</h5>
								<p
									css={css`
										font-size: 1.1rem;
									`}
								>
									{language === 'nepali' ? (
										<Fragment>
											{['ito_admin'].includes(user?.role)
												? <Fragment>
													{nagarpalika?.name_np?.includes('गाउँ') ? 'गाउँ ' : "नगर "}कार्यपालिकाकाे कार्यालय
												</Fragment>
												:
												profile?.ward_info?.name_np ?
													user?.organization?.type === 'sakha'
														? `${profile?.ward_info?.name_np} शाखा`
														: `${profile?.ward_info?.name_np} नं. वडा कार्यालय`
													: null}
										</Fragment>
									) : (
										<Fragment>
											{user?.role === 'ito_admin'
												? user?.organization?.type === 'sakha'
													? `${profile?.ward_info?.name_en} Department`
													: 'Office Of the Municipal Executive'
												: ` ${profile?.ward_info?.name_en} No. Ward Office`}
										</Fragment>
									)}
								</p>
								<p
									className='mt-1'
									css={css`
										font-size: 0.9rem;
									`}
								>
									{language === 'english' ? nagarpalikaInfo.address_en : nagarpalikaInfo.address_np}
									<br className='mt-2 block' />
									{language === 'english' ? nagarpalikaInfo?.province_en : nagarpalikaInfo?.province_np}, {language === "english" ? "Nepal" : 'नेपाल'}
								</p>
							</div>
						) : (
							<div>
								<h5>{language === 'english' ? nagarpalika?.name_en : nagarpalika?.name_np}</h5>
								<p
									css={css`
										font-size: 1.1rem;
									`}
								>
									{language === 'nepali' ? (
										<Fragment>
											{user?.role === 'ito_admin'
												? <Fragment>
													{nagarpalika?.name_np?.includes('गाउँ') ? 'गाउँ ' : "नगर "}
													कार्यपालिकाकाे कार्यालय
												</Fragment>
												: profile?.ward_info?.name_np ? `${profile.ward_info.name_np} नं. वडा कार्यालय` : ''}
										</Fragment>
									) : (
										<Fragment>
											{user?.role === 'ito_admin'
												? `Office Of the ${nagarpalika?.name_np?.includes('गाउँ') ? 'Rural  ' : ""}Municipal Executive`
												: `Office of Ward No. ${profile?.ward_info?.name_en}`}
										</Fragment>
									)}
								</p>
								<p
									className='mt-1'
									css={css`
										font-size: 0.9rem;
									`}
								>
									{language === 'english' ? nagarpalika?.address_en : nagarpalika?.address_np}
									<br className='mt-2 block' />
									{language === 'english' ? nagarpalika?.province_en : nagarpalika?.province_np}, {language === "english" ? "Nepal" : 'नेपाल'}
								</p>
							</div>
						)}
					</h5>
				</div>
			</div>
			<hr
				css={css`
					margin: 0 auto;
					width: 90%;
					border-top-color: #dedede !important;
				`}
			/>
			<h4
				css={css`
					text-align: center;
					padding: 0.5rem 0;
					color: black;
				`}
			>
				एकीकृत प्रणाली
			</h4>
			<hr
				css={css`
					margin: 0 auto;
					width: 90%;
					border-top-color: #dedede !important;
				`}
			/>
			<div className="menus">
				<SidebarButtonRenderer data={data} user={user} />
			</div>
		</div>
	);
};

export default Sidebar;
