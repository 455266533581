import React, { useEffect } from "react";
import { VscLoading } from "react-icons/vsc";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutUser } from "../../_redux/slices/users";
import { useDispatch } from "react-redux";

export default function Signout() {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutUser(history));
        setTimeout(() => {
            history.push('/login');
        }, 1000)
    }, [dispatch, history])


    return (
        <div className="min-vh-100 d-flex flex-column align-items-center pt-10">
            <VscLoading className="spin" fontSize={56} />
            <p className="mt-3">Signing out in digital SSO</p>
        </div>
    )
}