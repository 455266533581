/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BsDot } from 'react-icons/bs';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import { useCallback } from 'react';
import { VscLoading } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { setUiError } from '../_redux/slices/ui';
import { fetchNagarpalika } from '../_redux/slices/nagarpalika';
import { fetchAppData } from '../_redux/slices/appData';

const containerStyle = css`
	height: 100vh;
	isolation: isolate;
	position: relative;
	background-color: #bee0f6;
	display: grid;
	place-content: center;
	.background {
		position: absolute;
		inset: 0;
		opacity: 0.4;
		background-image: url('/assets/images/nepal-map.svg');
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}
`;

const LoginWithConsent = () => {
	const { appId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const location = useLocation();
	const development = new URLSearchParams(location?.search).get('development');
	const [showPassword, setShowPassword] = useState();
	const [show, setShow] = useState({ type: 'login' });
	const [consent, setConsent] = useState({ name: 'Application' });

	const nagarpalika = useSelector((state) => state?.nagarpalika?.nagarpalika);

	useEffect(() => {
		try {
			dispatch(fetchNagarpalika());
			dispatch(fetchAppData());
		} catch (error) { }
	}, [dispatch]);

	const getToken = () => {
		const user = JSON.parse(window.localStorage.getItem('user'));
		if (user?.token) {
			return user.token;
		}
		return null;
	};
	const token = getToken();

	const getConsent = useCallback(async () => {
		try {
			const token = getToken();
			const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/o/consent/?id=${appId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			let res = await response.json();
			if (!res?.data?.token) throw new Error(res?.message ?? 'You are not allowed to authenticate');
			if (res.data.consent === true) {
				const redirectLink = document.createElement('a');
				const redirectURL = development === 'true' ? res.data.dev_url : res.data.redirect_url;
				redirectLink.href = redirectURL + `?token=${res.data.token}`;
				redirectLink.click();
			} else {
				setConsent(res.data);
				setShow({ type: 'consent' });
			}
		} catch (error) {
			if (error) setShow({ type: 'error', message: error.message });
		}
	}, [appId, development]);

	useEffect(() => {
		try {
			if (token) {
				setShow({ type: 'loading' });
				getConsent();
			}
		} catch (error) {
			if (error)
				setShow({
					type: 'error',
					message: error?.message || 'Something went wrong',
				});
		}
	}, [token, getConsent]);

	useEffect(() => {
		try {
			if (show === 'consent') {
				getConsent();
			}
		} catch (error) {
			setShow({
				type: 'error',
				message: error?.message || 'Something went wrong',
			});
		}
	}, [show, appId, getConsent]);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		try {
			const response = await fetchWrapper.post('/token/', data);
			if (!response?.data?.access) {
				throw new Error(response?.message || 'Unauthorized Access');
			}
			if (response.data.access) {
				let user = JSON.stringify({ token: response.data.access });
				window.localStorage.setItem('user', user);
				setShow({ type: 'consent' });
			}
		} catch (err) {
			if (err) {
				let message = '';
				if (err.status === 401) {
					message = 'माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन।';
				}
				else if (err.status === 500) {
					message = 'यो कार्य हाल सम्भव छैन। केहि समय पछि पुन: प्रयास गर्नुहोस्।';
				}
				dispatch(setUiError(message));
			}
		}
	};

	const handleConsent = async () => {
		try {
			const token = getToken();
			const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/o/consent/request/?id=${appId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			const res = await response.json();
			console.log(res);

			if (res.data?.token && res.data?.redirect_url) {
				const redirectLink = document.createElement('a');
				redirectLink.href = development
					? res.data.dev_url + `?token=${res.data.token}`
					: res.data.redirect_url + `?token=${res.data.token}`;
				redirectLink.click();
			} else
				throw new Error(
					res?.message || 'तपाईलाई यो एप चलाउन Access दिईएको छैन | कृपया पालिकामा सम्पर्क गर्नु होला | धन्यवाद |'
				);
		} catch (error) {
			if (error) {
				setShow({
					type: 'error',
					message: 'तपाईलाई यो एप चलाउन Access दिईएको छैन | कृपया पालिकामा सम्पर्क गर्नु होला | धन्यवाद |',
				});
			}
		}
	};
	return (
		<>
			{show.type === 'login' ? (
				<div className="login-sso" css={containerStyle}>
					<div className="background" />
					<div className="card">
						<div className="card-header">
							<img src="/assets/images/nep_logo.png" className="login-sso-logo" alt="logo" />
							<div className="nagarpalika-info">
								<p className="nagarpalika-info-name">{nagarpalika?.name_np ?? 'Ideabreed Nagarpalika'}</p>
								<p className="nagarpalika-info-name">{nagarpalika?.name_en ?? 'Ideabreed Nagarpalika'}</p>
								<p className="nagarpalika-info-slogan">{nagarpalika?.slogan_np ?? 'Ideabreed Nagarpalika'}</p>
							</div>
						</div>
						<div className="divider">
							<p>एकीकृत लग इन प्रणाली</p>
						</div>
						<div className="card-body">
							<form
								method="POST"
								action="#"
								className="needs-validation"
								noValidate=""
								onSubmit={handleSubmit(onSubmit)}
							>
								<div className="form-group">
									<label htmlFor="email">ईमेल</label>
									<input
										id="email"
										type="username"
										className="form-control"
										name="username"
										required
										autoFocus
										{...register('email')}
									/>
									<div className="invalid-feedback">कृपया तपाईंको ईमेल भर्नुहोस्</div>
									<p className="text-small text-danger">{errors.username?.message}</p>
								</div>

								<div className="form-group">
									<label htmlFor="password" className="control-label">
										पासवर्ड
									</label>
									<div className="position-relative">
										<Form.Control
											css={formInput}
											type={showPassword ? 'text' : 'password'}
											name="password"
											{...register('password')}
											tabIndex="2"
										/>
										<div css={eye}>
											{showPassword ? (
												<AiFillEyeInvisible
													css={css`
														color: grey;
													`}
													onClick={() => setShowPassword(false)}
												/>
											) : (
												<AiFillEye
													css={css`
														color: grey;
													`}
													onClick={() => setShowPassword(true)}
												/>
											)}
										</div>
									</div>
									<div className="invalid-feedback">कृपया तपाईको पासवर्ड भर्नुहोस्</div>
									<p className="text-small text-danger">{errors.password?.message}</p>
								</div>
								<div className="d-flex justify-content-end align-items-center mb-4">
									{/* <div className="custom-control custom-checkbox">
										<input
											type="checkbox"
											name="remember"
											className="custom-control-input"
											tabIndex="3"
											id="remember-me"
										/>
										<label className="control-label custom-control-label" htmlFor="remember-me">
											मलाई सम्झनुहोस्
										</label>
									</div> */}
									<div>
										<Link to="/forgot-password" className="text-small">
											<label className="control-label pointer">पासवर्ड भुल्नु भयो?</label>
										</Link>
									</div>
								</div>

								<Button type="submit" className="btn btn-login">
									लगइन
								</Button>
							</form>
						</div>
					</div>
				</div>
			) : show.type === 'consent' ? (
				<div css={containerStyle} className="login-sso">
					<div className="background" />
					<div
						css={css`
							width: max-content;
							height: max-content;
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
						className="card"
					>
						<div className="card-header">
							<img src="/assets/images/nep_logo.png" className="login-sso-logo" alt="logo" />
							<div className="nagarpalika-info">
								<p className="nagarpalika-info-name">{nagarpalika?.name_np ?? 'Ideabreed Nagarpalika'}</p>
								<p className="nagarpalika-info-name">{nagarpalika?.name_en ?? 'Ideabreed Nagarpalika'}</p>
								<p className="nagarpalika-info-slogan">{nagarpalika?.slogan_np ?? 'Ideabreed Nagarpalika'}</p>
							</div>
						</div>
						<div className="divider">
							<p>एकीकृत लग इन प्रणाली</p>
						</div>
						<div className="card-body">
							{/* <div className="d-flex justify-content-center mb-3">
								<img src="/assets/images/nep_logo.png" style={{ width: '100px' }} alt="logo" />
							</div>
							<h2 className="text-primary" style={{ fontWeight: 700 }}>
								{landingData?.title}
							</h2> */}
							<h4 className="mb-4 mx-4" style={{ color: '#444' }}>
								तपाईंको{' '}
								<span className="" style={{ fontWeight: 600, color: 'black' }}>
									{consent?.name}
								</span>{' '}
								खातामा Access गर्न चाहन्छ ।
							</h4>
							{/* <hr id="consent-hr" /> */}

							<p className="mb-2 px-4" style={{ color: '#444' }}>
								यसले{' '}
								<span className="" style={{ fontWeight: 600, color: 'black' }}>
									{consent?.name}
								</span>{' '}
								लाई निम्न कार्यका निम्ति अनुमति प्रदान गर्नेछ ।
							</p>
							<div>
								<ul className="mb-5 px-5" style={{ color: '#444' }}>
									<li className="mb-2 d-flex">
										<BsDot />
										<span>
											तपाइलाई <strong style={{ color: 'black' }}>एकीकृत प्रणाली</strong> मा रहेको व्यक्तिगत जानकारीसंग
											आबद्द गर्न,
										</span>
									</li>
									<li className="mb-2 d-flex">
										<BsDot />
										<span>सार्वजनिक रूपमा उपलब्ध गराउनुभएको व्यक्तिगत जानकारी हेर्न,</span>
									</li>
									<li className="mb-2 d-flex">
										<BsDot />
										<span>व्यक्तिगत जानकारी सम्पादन गर्न वा हटाउन ।</span>
									</li>
								</ul>
								<div>
									<h5 className="mb-2 px-4">
										के तपाई स्वीकृत गरी अगाडी बढ्न चाहनुहुन्छ ?
										{/* <span className="text-primary">{consent?.name}</span> */}
									</h5>
									{/* <p>
                  You may be sharing sensitive info with this site or app. You
                  can always see or remove access in your{" "}
                  <Link to="/" className="text-primary">
                    Auth Server Account
                  </Link>
                </p> */}
									{/* <hr id="consent-hr" /> */}
									<div className="mt-4 d-flex justify-content-end align-items-center">
										<Button className="btn-neutral" onClick={() => history.push('/')}>
											रद्द गर्नुहोस्
										</Button>
										<Button onClick={handleConsent} variant="primary" className="ml-2 btn-login">
											स्वीकृत गर्नुहोस्
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : show.type === 'loading' ? (
				<div style={{ height: '100vh' }} className="d-flex flex-column align-items-center justify-content-center">
					<VscLoading fontSize={56} className="text-primary spin" />
				</div>
			) : (
				<div css={containerStyle} className="login-sso max-w-300">
					<div className="background" />
					<div
						css={css`
							width: max-content;
							border-radius: 8px;
						`}
						className="card"
					>
						<div className="card-header">
							<img src="/assets/images/nep_logo.png" className="login-sso-logo" alt="logo" />
							<div className="nagarpalika-info">
								<p className="nagarpalika-info-name">{nagarpalika?.name_np ?? 'Ideabreed Nagarpalika'}</p>
								<p className="nagarpalika-info-name">{nagarpalika?.name_en ?? 'Ideabreed Nagarpalika'}</p>
								<p className="nagarpalika-info-slogan">{nagarpalika?.slogan_np ?? 'Ideabreed Nagarpalika'}</p>
							</div>
						</div>
						<div className="divider">
							<p>एकीकृत लग इन प्रणाली</p>
						</div>
						<div className="card-body">
							<h3 className="text-center text-danger my-5">
								{show.type === 'error' && show?.message ? show?.message : 'Something went wrong'}
							</h3>
							<Button variant="primary btn-login" className="mt-3" onClick={() => history.push('/')}>
								होम पेजमा जानुहोस् |
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LoginWithConsent;

const formInput = css`
	display: block;
	width: 100% !important;
	float: none !important;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
	color: #495057 !important;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
`;

const eye = css`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
`;
