/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import {
	createApplications,
	deleteApplication,
	fetchApplications,
	editApplication,
} from '../../_redux/slices/application';
import PaginationComp from '../../components/Pagination';
import ApplicationModules from '../ApplicationModules';
import { useParams, useHistory } from 'react-router-dom';
import CustomTable from '../../components/Table';
import { checkPermission } from '../../_helpers/roles/check-roles';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { VscLoading } from 'react-icons/vsc';
import { FaEdit, FaTrash } from 'react-icons/fa';

import DynamicIcons, { ICONS_ARRAY } from '../../components/DynamicIcons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import InputField from '../../components/InputField';

const Application = ({ count, applications, fetchApplications }) => {
	const { pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [show, setShow] = useState(false);
	const [editApplication, setEditApplication] = useState(null);

	const handleClose = () => {
		setEditApplication(null);
		setShow(false)
	};
	const handleShow = () => setShow(true);

	const user = useSelector((state) => state.users.user);

	useEffect(() => {
		let isPrivilleged = ['ito_admin', 'superuser'].includes(user?.role);
		if (isPrivilleged) {
			fetchApplications(page);
		} else {
			history.push('/application/user');
		}

	}, [fetchApplications, page, user?.role, pageNum, history]);

	const loading = useSelector((state) => state.applications.loading);

	const handleOnSuccess = () => {
		fetchApplications(page);
		handleClose();
	}

	const handleApplicationDelete = async (id) => {
		await fetchWrapper.delete(`/o/application/${id}`);
		Swal.fire(
			'Deleted!',
			'The application has been deleted.',
			'success'
		)
		handleOnSuccess();
	}

	const headers = [
		{
			key: 'app_id',
			title: 'Application ID',
			headerStyleOverwrite: css`
				width: 20%;
			`,
		},
		{
			key: 'name',
			title: 'Application Name',
			headerStyleOverwrite: css`
				width: 20%;
			`,
		},
		{
			key: 'domain',
			title: 'Domain',
		},
		{
			key: 'redirect_url',
			title: 'Redirect URL',
		},
		{
			key: 'dev_url',
			title: 'Dev URL',
		},
		{
			key: 'app_secret',
			title: 'Application Secret',
		},
		...(checkPermission(user?.role, 'create:application') ? [{
			key: 'actions',
			title: 'कार्यहरू',
			render: (application) => (
				<Fragment>
					<Button
						className="mr-1"
						onClick={() => {
							setEditApplication(application);
							setShow(true);
						}
						}
					>
						<FaEdit />
					</Button>
					<Button
						variant="danger"
						className="mr-1"
						onClick={() => {
							Swal.fire({
								title: 'Are you sure?',
								text: "You won't be able to revert this!",
								icon: 'warning',
								showCancelButton: true,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								confirmButtonText: 'Yes, delete it!'
							}).then((result) => {
								if (result.isConfirmed) {
									handleApplicationDelete(application.app_id);
								}
							})
						}
						}
					>
						<FaTrash />
					</Button>
				</Fragment >
			)
		}] : [])
	];

	if (pageNum !== 'user') {
		return (
			<Fragment>
				<Card
					css={css`
						border-radius: 8px;
						box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
					`}
				>
					<Card.Header className="card-header-custom px-3">
						<h2>Application</h2>

						{checkPermission(user?.role, 'create:application') &&
							<Button onClick={handleShow}>Register Application</Button>
						}
					</Card.Header>

					<Card.Body className="p-0 px-3">
						<CustomTable headers={headers} data={applications} isLoading={loading} />
						{count > 10 && <PaginationComp total={count} page={page} setPage={setPage} />}
					</Card.Body>

					<ApplicationModal show={show} handleClose={handleClose} value={editApplication} onSuccess={handleOnSuccess} />
				</Card>
			</Fragment>
		);
	} else {
		return <ApplicationModules />;
	}
};

const mapStateToProps = (state) => ({
	fiscalyear: state.fiscal.fiscalyear,
	applications: state.applications.applications,
	count: state.applications.count,
});

const mapDispatchToProps = {
	fetchApplications,
	createApplications,
	deleteApplication,
	editApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);


const INITIAL_APPLICATION_VALUE = { icon: '', name: '', domain: '', redirect_url: '', dev_url: `http://localhost:8848/login` };
const ApplicationModal = ({ show, handleClose, value, onSuccess }) => {
	const [application, setApplication] = useState(INITIAL_APPLICATION_VALUE);
	const language = useSelector(state => state.language.language);

	const [loading, setLoading] = useState(false);

	const handleChange = (event) => {
		setApplication({
			...application,
			[event.target.name]: event.target.value
		})
	}

	useEffect(() => {
		if (value) {
			setApplication(value);
		}
	}, [value])

	const clearApplication = () => setApplication(INITIAL_APPLICATION_VALUE)
	const handleSave = async () => {
		try {
			setLoading(true);
			if (value) {
				await fetchWrapper.patch(`/o/application/${value.app_id}/`, application);
			}
			else {
				await fetchWrapper.post(`/o/application/`, { ...application, });
			}
			onSuccess();
			toast.success("एप सफलतापूर्वक दर्ता गरियो।")
			clearApplication();
			setLoading(false);
			handleClose();
		}
		catch (err) {
			setLoading(false);
		}
	}
	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>Application</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column'>
					<div className="mb-3">
						<div>
							Icon:
						</div>
						<div className='d-flex flex-wrap'>
							{
								ICONS_ARRAY.map(icon => {
									let cssStyle = `
									color: #303030;
									:hover {
										background-color: #508bf8;
										color: white;
									}
									${application.icon === icon ? 'background:#508bf8;color: white;' : ''}
									svg{
										font-size: 28px;
									}
								`
									return <div className="border p-3 mb-1 mr-1 cursor-pointer rounded"
										onClick={() => {
											const iconData = {
												target: {
													name: 'icon',
													value: icon
												}
											}
											handleChange(iconData);
										}}

										css={css(cssStyle)}>
										<DynamicIcons name={icon} />
									</div>
								})
							}
						</div>

					</div>
					<div className="mb-3 d-flex">
						<label htmlFor='name'>
							Name:
						</label>
						<InputField
							elementId={'name'}
							language={language}
							value={application.name}
							getInputData={(value) => {
								const nameData = {
									target: {
										name: 'name',
										value: value
									}
								}
								handleChange(nameData);
							}}
							disabled={loading}
							className={`form-control`}
							css={css`
										width: 100%;
										padding: 0.375rem 0.75rem !important;
									`}
						/>
					</div>
					<div className="mb-3 d-flex">
						<label htmlFor='domain'>
							Domain:
						</label>
						<input className="py-1 px-2 w-100" onChange={handleChange} value={application.domain} id="domain" name="domain" placeholder='patrachar.ideabreed.net' disabled={loading} />
					</div>
					<div className="mb-3 d-flex">
						<label htmlFor='redirect_url'>
							Redirect URL:
						</label>
						<input className="py-1 px-2 w-100" onChange={handleChange} value={application.redirect_url} id="redirect_url" name="redirect_url" disabled={loading} />
					</div><div className="mb-3 d-flex">
						<label htmlFor='redirect_url'>
							Dev URL:
						</label>
						<input className="py-1 px-2 w-100" onChange={handleChange} value={application.dev_url} id="dev_url" name="dev_url" disabled={loading} />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSave}>
					{
						loading ?
							<VscLoading className="spin" />
							:
							"Save"
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}