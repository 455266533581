import styled from 'styled-components';

export const StyledInput = styled.input`
	background: #fff;
	border: 1px solid #ccc;
	font: 14px 'Mukta';
	color: #333;
	padding: 3px 4px;
`;

export const StyledTextArea = styled.textarea`
	width: 100%;
	background: #fff;
	border: 1px solid #ccc;
	font: 14px 'Mukta';
	color: #333;
	padding: 3px 4px;
`;
