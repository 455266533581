import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiError, setUiMessage } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	applications: [],
	count: 0,
};

// A slice
const applicationSlice = createSlice({
	name: 'applications',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setApplications: (state, action) => {
			state.applications = action.payload.results;
			state.count = action.payload.count;
		},
		addApplication: (state, action) => {
			state.applications = [action.payload, ...state.applications];
			state.count += 1;
		},
		updateApplication: (state, action) => {
			const tempApplications = state.applications.map((app) => {
				if (app.app_id === action.payload.app_id) {
					return action.payload;
				} else {
					return app;
				}
			});
			state.applications = tempApplications;
		},
		removeApplication: (state, action) => {
			const tempApplication = state.applications.filter((app) => app.app_id !== action.payload);
			state.applications = tempApplication;
			state.count -= 1;
		},
	},
});

// Actions generated from the slice
const { setLoading, setApplications, addApplication, removeApplication, updateApplication } = applicationSlice.actions;

// export user selector to get the slice in any component
export const applicationSelector = (state) => state.applications;

// export The reducer
const applicationReducer = applicationSlice.reducer;

export default applicationReducer;

// Actions
export const fetchApplications =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const applications = await fetchWrapper.get(`/o/application/?page=${page}`);
			dispatch(setApplications(applications.data));
			dispatch(setLoading(false));
		} catch (error) {
			dispatch(setUiError(error.message));
			dispatch(setLoading(false));
		}
	};

export const createApplications = (data, handleApplicationForm) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.post('/o/application/', data);
		dispatch(addApplication(response.data));
		handleApplicationForm();
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setUiError(error.message));
		dispatch(setLoading(false));
	}
};

export const editApplication = (data, id, handleApplicationForm) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.patch(`/o/application/${id}/`, data);
		dispatch(updateApplication(response.data));
		handleApplicationForm();
		dispatch(setUiMessage(response?.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const deleteApplication = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		await fetchWrapper.delete(`/o/application/${id}/`);
		dispatch(removeApplication(id));
		dispatch(setUiMessage('Application removed Successfully'));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setUiError(error.message));
		dispatch(setLoading(false));
	}
};
