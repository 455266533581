const rules = {
	superuser: {
		static: [
			'view:arthikBarsa',
			'view:wodaKaralayaHaru',
			'view:sakhaKaryalayaHaru',
			'view:applications',
			'view:nagarpalikaDetails',
			'view:profile',
			'view:changePassword',
			'view:support',
			'create:application'
		],
	},
	ito_admin: {
		static: [
			'view:arthikBarsa',
			'view:wodaKaralayaHaru',
			'view:sakhaKaryalayaHaru',
			'view:applications',
			'view:nagarpalikaDetails',
			'view:profile',
			'view:changePassword',
			'view:support',
		],
	},
	ward_admin: {
		static: [
			'view:applications',
			'view:settings',
			'view:sawikKoPalika',
			'view:nayaAawedakDarta',
			'view:support',
			'view:profile',
			'view:changePassword',
		],
	},
	ward_user: {
		static: ['view:applications', 'view:support', 'view:profile', 'view:changePassword'],
	},
	ward_kawa: {
		static: ['view:applications', 'view:support', 'view:profile', 'view:changePassword'],
	},
	ward_paper_issuer: {
		static: ['view:templates', 'view:support', 'view:profile', 'view:changePassword'],
	},
	mayor: {
		static: ['view:reports', 'view:profile', 'view:changePassword'],
	},
	upa_mayor: {
		static: ['view:reports', 'view:profile', 'view:changePassword'],
	},
};

export default rules;
